import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

import History from "../components/History"

const HistoryPage = () => {
  return (
    <Layout>
      <SEO title="About" />
      <History />
    </Layout>
  )
}

export default HistoryPage
