// 2019
import img20191 from "../../../images/about/about-us-1.jpg"
import img20192 from "../../../images/about/about-us-2.jpg"
// import img20193 from "../../../images/about/about-us-3.jpg"
// import img20194 from "../../../images/about/about-us-4.jpg"
// import img20195 from "../../../images/about/about-us-5.jpg"
import img20196 from "../../../images/about/about-us-6.jpg"
import img20197 from "../../../images/about/about-us-7.jpg"
import img20198 from "../../../images/about/about-us-8.jpg"
import img20199 from "../../../images/about/about-us-9.jpg"
import img201910 from "../../../images/about/about-us-10.jpg"
// import img201911 from "../../../images/about/about-us-11.jpg"
import img201912 from "../../../images/about/about-us-12.jpg"
import img201913 from "../../../images/about/about-us-13.jpg"
import img201914 from "../../../images/about/about-us-14.jpg"
// import img201915 from "../../../images/about/about-us-15.jpg"
// import img201916 from "../../../images/about/about-us-16.jpg"
import img201917 from "../../../images/about/about-us-17.jpg"
import img201918 from "../../../images/about/about-us-18.jpg"
import img201919 from "../../../images/about/about-us-19.jpg"
import img201920 from "../../../images/about/about-us-20.jpg"
import img201921 from "../../../images/about/about-us-50.jpg"
import img201922 from "../../../images/about/about-us-51.jpg"
import img201923 from "../../../images/about/about-us-52.jpg"
import img201924 from "../../../images/about/about-us-53.jpg"
import img201925 from "../../../images/about/about-us-54.jpg"
import img201926 from "../../../images/about/about-us-55.jpg"
import img201927 from "../../../images/about/about-us-56.jpg"
import img201928 from "../../../images/about/about-us-57.jpg"
import img201929 from "../../../images/about/about-us-58.jpg"

import img201930 from "../../../images/about/about-us-59.jpg"
import img201931 from "../../../images/about/about-us-60.jpg"

import img202301 from "../../../images/about/about-us-001.jpg"
import img202302 from "../../../images/about/about-us-002.jpg"
import img202303 from "../../../images/about/about-us-003.jpg"
import img202304 from "../../../images/about/about-us-004.jpg"
import img202305 from "../../../images/about/about-us-005.jpg"

const CULTURE_DATA = [
  {
    year: "2023",
    img: img202301,
  },
  {
    year: "2023",
    img: img202302,
  },
  {
    year: "2023",
    img: img202303,
  },
  {
    year: "2023",
    img: img202304,
  },
  {
    year: "2023",
    img: img202305,
  },
  {
    year: "2019",
    img: img20191,
  },
  {
    year: "2019",
    img: img20192,
  },
  // {
  //   year: "2019",
  //   img: img20193,
  // },
  // {
  //   year: "2019",
  //   img: img20194,
  // },
  // {
  //   year: "2019",
  //   img: img20195,
  // },
  {
    year: "2019",
    img: img20196,
  },
  {
    year: "2019",
    img: img20197,
  },
  {
    year: "2019",
    img: img20198,
  },
  {
    year: "2019",
    img: img20199,
  },
  {
    year: "2019",
    img: img201910,
  },
  // {
  //   year: "2019",
  //   img: img201911,
  // },
  {
    year: "2019",
    img: img201912,
  },
  {
    year: "2019",
    img: img201913,
  },
  {
    year: "2019",
    img: img201914,
  },
  // {
  //   year: "2019",
  //   img: img201915,
  // },
  // {
  //   year: "2019",
  //   img: img201916,
  // },
  {
    year: "2019",
    img: img201917,
  },
  {
    year: "2019",
    img: img201918,
  },
  {
    year: "2019",
    img: img201919,
  },
  {
    year: "2019",
    img: img201920,
  },
  {
    year: "2019",
    img: img201921,
  },
  {
    year: "2019",
    img: img201922,
  },
  {
    year: "2019",
    img: img201923,
  },
  {
    year: "2019",
    img: img201924,
  },
  {
    year: "2019",
    img: img201925,
  },
  {
    year: "2019",
    img: img201926,
  },
  {
    year: "2019",
    img: img201927,
  },
  {
    year: "2019",
    img: img201928,
  },
  {
    year: "2019",
    img: img201929,
  },
  {
    year: "2019",
    img: img201930,
  },
  {
    year: "2019",
    img: img201931,
  },
]

export default CULTURE_DATA
