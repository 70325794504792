import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { Swiper } from "swiper/dist/js/swiper.esm"

import media from "../../../styles/media"

import EVENT_DATA from "./data"
import EventBlock from "./eventBlock"
import About from "./about"

const Container = styled.section`
  padding: var(--gutter-m) var(--gutter-s) var(--gutter-l) var(--gutter-s);
  h1 {
    font-size: 3rem;
    line-height: 1;
    transform: translateY(30%);
    position: relative;
    z-index: 2;
  }
  ${media.tablet`
    padding: var(--gutter-m) 0 var(--gutter-l) var(--gutter-l);
    h1 {
      font-size: 5.5rem;
    }
  `}
`

const SwiperContainer = styled.div`
  margin-bottom: var(--gutter-s);
  ${media.tablet`
  `}
`

const Slide = styled.div`
  ${media.tablet`
    width: calc(50vw - 40px);
    &:last-child {
      margin-right: calc(101% - (50vw - 40px));
    }
  `}
`

const SwiperControls = styled.div`
  height: 60px;
  align-items: center;
  .controls {
    span {
      font-size: 0.9em;
    }
    display: flex;
    justify-content: space-between;
    #prev,
    #next {
      opacity: 0.25;
      transition: all 250ms ease-out;
      padding: 1rem;
      &.active {
        opacity: 1;
        padding: 1rem;
      }
      &:first-child {
        margin-right: 10px;
      }
    }
  }
  ${media.tablet`
    width: calc(50vw - 50px);
  `}
`

const Events = () => {
  const swiperElement = useRef(null)
  const swiper = useRef(null)

  const [activeSlide, setActiveSlide] = useState(0)
  const [zoom, setZoom] = useState(false)

  const slidePrev = () => {
    if (swiper.current) {
      swiper.current.slidePrev()
    }
  }

  const slideNext = () => {
    if (swiper.current) {
      swiper.current.slideNext()
    }
  }

  const renderEvents = () => {
    return EVENT_DATA.map((event, i) => {
      return (
        <Slide key={i} className="swiper-slide">
          <EventBlock
            year={event.year}
            img={event.img}
            insta_handle={event.insta_handle}
            zoom={zoom}
          />
        </Slide>
      )
    })
  }

  useEffect(() => {
    swiper.current = new Swiper(swiperElement.current, {
      speed: 1000,
      touchRatio: 1.75,
      longSwipesRatio: 0.2,
      watchSlidesProgress: true,
      watchSlidesVisibility: true,
      touchStartPreventDefault: false,
      slidesPerView: 1,
      spaceBetween: 80,
      initialSlide: activeSlide,
      breakpointsInverse: true,
      breakpoints: {
        767: {
          slidesPerView: "auto",
        },
      },
      on: {
        transitionEnd: () => {
          if (swiper.current) {
            setActiveSlide(swiper.current.activeIndex)
          }
        },
        touchStart: () => {
          setZoom(true)
        },
        touchEnd: () => {
          setZoom(false)
        },
      },
    })
    return function cleanup() {}
  }, [activeSlide])

  return (
    <div id="history">
      <Container name="artists">
        <h1 className="font-black">history</h1>
        <SwiperContainer
          className="swiper-container slider-swipe"
          ref={swiperElement}
        >
          <div className="swiper-wrapper"> {renderEvents()} </div>
        </SwiperContainer>
        <SwiperControls>
          <div className="controls">
            <div>
              <span>0{activeSlide + 1}</span>
              <span className="divider">&#8213;</span>
              <span>0{EVENT_DATA.length}</span>
            </div>
            <div>
              <span
                onClick={slidePrev}
                id="prev"
                className={activeSlide > 0 ? `active mouse-link` : `mouse-link`}
              >
                &#8592;
              </span>
              <span
                onClick={slideNext}
                className={
                  activeSlide < EVENT_DATA.length - 1
                    ? `active mouse-link`
                    : `mouse-link`
                }
                id="next"
              >
                &#8594;
              </span>
            </div>
          </div>
        </SwiperControls>
      </Container>
      <About />
    </div>
  )
}

export default Events
