import React from "react"

import Events from "./Events"
import About from "./About"
import Gallery from "./Gallery"

const History = () => {
  return (
    <>
      <About />
      <Gallery />
      <Events />
    </>
  )
}

export default History
