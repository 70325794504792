import React from "react"
import styled from "styled-components"

// import ImageWrapper from "../../elements/imageWrapper"
// import LinkBuy from "../../elements/linkBuy"

// import media from "../../../styles/media"

// const Container = styled.div`
//   display: grid;
//   grid-template: auto auto / 1fr 1fr 1fr;
//   padding: var(--gutter-s) var(--gutter-s) var(--gutter-l);
//   align-items: center;
//   p {
//     grid-row: 1 / 2;
//     grid-column: 1 / 3;
//     font-size: 1.25rem;
//     position: relative;
//     z-index: 1;
//     text-align: left;
//     /* padding-top: var(--gutter-s); */
//   }
//   .gatsby-image-wrapper {
//     opacity: 0.5;
//     grid-row: 1 / 2;
//     grid-column: 2 / 4;
//   }
//   ${media.tablet`
//     grid-template: auto auto / 3fr 3fr 1fr 2fr
//     padding: var(--gutter-l);
//     p {
//       grid-column: 1 / 4;
//       font-size: 1.5rem;
//       padding-left: 4em;
//     }
//     .gatsby-image-wrapper {
//       grid-column: 3 / 5;
//     }
//   `}
// `

const LinkContainer = styled.div`
  text-align: center;
  margin-left: -80px;
  /* margin-top: 2em; */
  padding: 5vh 0 8vh 0;
`
// const ContainerLeft = styled.div`
//   display: grid;
//   /* grid-template-columns: 1fr 1fr; */
//   grid-template: auto auto / repeat(8, 1fr);
//   align-items: center;
//   p {
//     grid-column: 2 / 8;
//     grid-row: 1;
//     z-index: 1;
//     font-size: 1.25rem;
//     /* margin-top: 10rem; */
//   }
//   .gatsby-image-wrapper {
//     opacity: 0.6;
//     grid-column: 1 / 6;
//     grid-row: 1;
//   }
//   @media (min-width: 768px) {
//     p {
//       font-size: 1.5rem;
//       grid-column: 4 / 8;
//       padding-left: 4em;
//     }
//     .gatsby-image-wrapper {
//       grid-column: 1 / 5;
//       grid-row: 1;
//     }
//   }
// `
// const ContainerRight = styled.div`
//   padding: 1.2rem;
//   padding-top: var(--gutter-l);
//   display: grid;
//   grid-template: auto auto / repeat(8, 1fr);
//   align-items: center;
//   p {
//     grid-column: 1 / 7;
//     grid-row: 1;
//     z-index: 1;
//     /* margin-top: 10rem; */
//     font-size: 1.25rem;
//   }
//   .gatsby-image-wrapper {
//     opacity: 0.7;
//     grid-column: 3 / 9;
//     grid-row: 1;
//   }
//   @media (min-width: 768px) {
//     padding: var(--gutter-l);
//     p {
//       grid-column: 2 / 6;
//       font-size: 1.5rem;
//     }
//     .gatsby-image-wrapper {
//       grid-column: 5 / 9;
//     }
//   }
// `

const About = () => {
  return (
    <>
      {/* <Container>
        <p>
          The South African International Tattoo Convention was founded in 2017
          and is hosted by Cape Electric Tattoo. Each year we showcase 90 of the
          best tattooers from all over the world at the V&A Waterfront, Cape
          Town.
        </p>
        <ImageWrapper src="events/waldo_romy.jpg" class="image-1" />
      </Container> */}
      {/* <ContainerLeft>
        <ImageWrapper src="events/group-photo.jpg" class="image-2" />
        <p>
          The convention is open to the entire family and under 12&#39;s get in
          for free. <br /> Everybody is welcome, we look forward to seeing you
          back in 2020!
        </p>
      </ContainerLeft> */}
      {/* <ContainerRight>
        <ImageWrapper src="events/equipment.jpg" class="image-3" />
        <p>
          Whether you are a dedicated tattoo enthusiast or want to learn more
          about tattooing, we encourage you to come and experience it for
          yourself.
        </p>
      </ContainerRight>
      <ContainerRight>
        <p>
          Over the course of the weekend, 10 of our favourite local bands will
          keep you entertained. There is also a deck bar, food trucks, beer
          garden, goods market, great coffee and the best doughnuts in town
          throughout the convention.{" "}
        </p>
      </ContainerRight> */}
      {/* <LinkContainer>
        <LinkBuy />
      </LinkContainer> */}
    </>
  )
}

export default About
