const EVENT_DATA = [
  {
    year: "2023",
    img: "events/posters/2023.jpg",
    insta_handle: "mattxcannon",
  },
  {
    year: "2020",
    img: "events/posters/2020.jpg",
    insta_handle: "mattxcannon",
  },
  {
    year: "2019",
    img: "events/posters/2019.jpg",
    insta_handle: "waldodelrocca",
  },
  {
    year: "2018",
    img: "events/posters/2018.jpg",
    insta_handle: "ross_starcrossed",
  },
  {
    year: "2017",
    img: "events/posters/2017.jpg",
    insta_handle: "garethlloyd.cet",
  },
]

export default EVENT_DATA
