import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import { Swiper } from "swiper/dist/js/swiper.esm"

import media from "../../../styles/media"

import CULTURE_DATA from "./data"

const Container = styled.div`
  padding: var(--gutter-s);
  .swiper-container {
    height: 400px;
  }
  .swiper-wrapper {
    max-width: 100%;
  }
  .swiper-slide {
    width: auto;
    img {
      height: 100%;
      object-fit: contain;
    }
  }
  .flex-nav {
    display: flex;
    margin: 0;
    align-items: center;
  }
  ${media.tablet`
    padding: var(--gutter-l) 0 var(--gutter-l) var(--gutter-l);
    .swiper-slide {
      max-height: 500px;
      width: auto;
      margin-right: 30px;
    }
  `}
`

const Arrows = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  /* margin-right: 2rem; */
  div {
    opacity: 0.25;
    transition: all 250ms ease-out;
    padding: 1rem;
    &.active {
      opacity: 1;
      padding: 1rem;
    }
    &:first-child {
      margin-right: 10px;
    }
  }
  ${media.tablet`
    width: calc(31% + 10rem);
    justify-content: flex-end;
  `}
`

const ActiveSlide = styled.div`
  margin-left: 2rem;
  ${media.tablet`
    margin: 0;
  `}
`

const Slide = styled.div`
  position: relative;
  .img-container {
    width: auto;
    height: 100%;
    overflow: hidden;
    img {
      width: auto;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      transition: transform 2500ms ease-out !important;
      transform: ${props => (props.zoom ? `scale(1.1)` : `scale(1)`)};
    }
    &:hover {
      img {
        transition: transform 2500ms ease-out !important;
        transform: scale(1.1);
      }
    }
  }
`

const Culture = () => {
  // eslint-disable-next-line no-unused-vars
  const [year, setYear] = useState("2019")

  const [zoom, setZoom] = useState(false)

  const renderCultureData = () => {
    return CULTURE_DATA.map((culture, index) => {
      return (
        <Slide key={index} className="swiper-slide" year={year} zoom={zoom}>
          <div className="img-container">
            <img src={culture.img} />
          </div>
        </Slide>
      )
    })
  }

  const swiperElement = useRef(null)
  const swiper = useRef(null)

  const [activeSlide, setActiveSlide] = useState(0)

  useEffect(() => {
    swiper.current = new Swiper(swiperElement.current, {
      speed: 1000,
      freeMode: true,
      freeModeSticky: true,
      breakpointsInverse: true,
      touchStartPreventDefault: false,
      rebuildOnUpdate: true,
      slidesPerView: 1,
      spaceBetween: 30,
      centeredSlides: true,
      breakpoints: {
        768: {
          slidesPerView: "auto",
          centeredSlides: false,
        },
      },
      on: {
        transitionEnd: () => {
          if (swiper.current) {
            setActiveSlide(swiper.current.activeIndex)
          }
        },
        touchStart: () => {
          setZoom(true)
        },
        touchEnd: () => {
          setZoom(false)
        },
      },
    })
  }, [])

  const slideNext = () => {
    if (swiper.current) {
      swiper.current.slideNext()
    }
  }
  const slidePrev = () => {
    if (swiper.current) {
      swiper.current.slidePrev()
    }
  }

  return (
    <Container>
      <div ref={swiperElement} className="swiper-container slider-swipe">
        <div className="swiper-wrapper">{renderCultureData()}</div>
      </div>
      <div className="flex-nav">
        <ActiveSlide>
          <span>
            {activeSlide < 9 ? "0" : null}
            {activeSlide + 1}
          </span>
          <span className="divider">&#8213;</span>
          <span>{renderCultureData().length}</span>
        </ActiveSlide>
        <Arrows>
          <div
            className={activeSlide > 0 ? `active mouse-link` : `mouse-link`}
            onClick={slidePrev}
          >
            &#8592;
          </div>
          <div
            className={
              activeSlide < CULTURE_DATA.length - 1
                ? `active mouse-link`
                : `mouse-link`
            }
            onClick={slideNext}
          >
            &#8594;
          </div>
        </Arrows>
      </div>
    </Container>
  )
}

export default Culture
