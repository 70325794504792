import React from "react"
import styled from "styled-components"

import media from "../../../styles/media"

const Container = styled.div`
  padding: var(--gutter-m) var(--gutter-s) var(--gutter-l) var(--gutter-s);
  ${media.tablet`
    padding: var(--gutter-m) 0 var(--gutter-l) var(--gutter-l);
  `}
`

const Title = styled.div`
  margin-bottom: var(--gutter-m);
  h1 {
    font-size: 3rem;
    line-height: 1;
    transform: translateY(30%);
    position: relative;
    z-index: 2;
  }
  ${media.tablet`
    margin-bottom: var(--gutter-l);
    h1 {
      font-size: 5.5rem;
    }
  `}
`

const Body = styled.div`
  margin-bottom: var(--gutter-m);
  p {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
  }
  ${media.tablet`
    margin-bottom: var(--gutter-l);
    max-width: 65vw;
  `}
`

const Video = styled.div`
  display: grid;
  grid-template: auto / 1fr 3fr;
  .heading {
    writing-mode: vertical-rl;
    transform: scale(-1);
    display: flex;
    justify-content: space-between;
    span {
      font-size: 4rem;
    }
  }
  iframe {
    width: 100%;
    height: 300px;
  }
  ${media.tablet`
    padding-right: var(--gutter-l);
    iframe {
      height: 500px;
    }
  `}
`

const About = () => {
  return (
    <Container>
      <Title>
        <h1 className="font-black">about</h1>
      </Title>
      <Body>
        <p>
          The South African International Tattoo Convention is a 3-day event
          that hosts over 120 of the world's best tattooers from over 20
          countries, giving you the chance to get tattooed by expert local and
          international artists specialising in a large variety of tattoo
          styles. This event is held annually in Cape Town. The convention is a
          heartfelt tattooer-owned and operated event. Each year we consider it
          a great honour to gather an amazing group of artists under one roof.
          Over the convention weekend in Cape Town , we host local bands, a beer
          tent, a cocktail bar, and serve you street food made by the best local
          food trucks around.
        </p>
        <p>
          After the three days of tattooing the fun is not over, the tattooers
          have established a few traditions like taking the train to swim with
          the african penguins, getting out for a boat trip in the V&A harbour
          and then embarking on a 3 day #tattoosafari, visiting some of the best
          Private game lodges in the country experiencing the Big 5.{" "}
        </p>
        <p>We can't wait to see you in Cape Town on 28-29-30 March 2025!</p>
      </Body>
      <Video>
        <div className="heading">
          <span className="font-black">2</span>
          <span className="font-black">0</span>
          <span className="font-black">1</span>
          <span className="font-black">9</span>
        </div>
        <iframe
          src="https://www.youtube.com/embed/uKbOEmqZW2w"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Video>
    </Container>
  )
}

export default About
